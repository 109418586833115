import { DOCUMENT, Location } from '@angular/common'
import { Injectable, Inject } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { SubUserStoreService } from "../store/services/subuser-store.service"

@Injectable({
  providedIn: 'root',
})
export class LocalizeHelperService {
  constructor(
    private router: Router,
    public subUserStoreService: SubUserStoreService,
    private location: Location,
    public translate: TranslateService,
    @Inject(DOCUMENT) private dom) {
    let fpart = this.router.url.split('/')[1]
    if (fpart && this.localizationAllowed.includes(fpart)) {
      this.urlLang = fpart
    }

    let l = localStorage.getItem('currentLanguage')
    if (l && this.localizationAllowed.includes(l)) {
      this.lang = l
    } else {
      this.lang = 'en'
    }
  }

  private lang: string
  private urlLang: string = ''

  setLang(lang: string, currentPath = true) {
    if (!this.localizationAllowed.includes(lang)) { return }

    if (currentPath)
      this.updateCurrentPath(lang);

    this.lang = lang
    this.subUserStoreService.currentLanguage = lang
    localStorage.setItem('currentLanguage', lang)
    this.translate.use(lang)
    this.urlLang = this.lang
  }

  getLangForced(): string {
    let l = localStorage.getItem('currentLanguage')
    if (l && this.localizationAllowed.includes(l)) {
      return l
    }

    return 'en';
  }

  setURLLang(lang, currentPath = true) {
    if (this.localizationAllowed.includes(lang)) {
      if (currentPath)
        this.updateCurrentPath(lang);

      this.urlLang = lang
      this.lang = lang
      this.subUserStoreService.currentLanguage = lang
      if (lang !== '') {
        this.translate.use(lang)
      }

      return true
    }

    if (!lang) {
      if (currentPath)
        this.updateCurrentPath('en');

      this.urlLang = ''
      this.lang = 'en'
      this.subUserStoreService.currentLanguage = this.lang
      if (lang !== '') {
        this.translate.use(this.lang)
      }

      return true
    }

    return false
  }

  getURLLang(): string {
    return this.urlLang
  }

  createLangMetaLinks() {
    const el = this.dom.querySelectorAll("[rel='alternate']")
    if (el.length) {
      el.forEach((item) => {
        item.remove()
      })
    }

    const domain = 'https://dinolingo.com'
    let basePart = this.router.url
    if (this.urlLang) {
      basePart = this.router.url.replace(`/${this.urlLang}`, '')
    }

    const link: HTMLLinkElement = this.dom.createElement('link')
    link.setAttribute('rel', 'alternate')
    link.setAttribute('href', `${domain}${basePart}`)
    link.setAttribute('hreflang', 'x-default')
    this.dom.head.appendChild(link)

    this.localizationAllowed.forEach((lang, i) => {
      const link: HTMLLinkElement = this.dom.createElement('link')

      link.setAttribute('rel', 'alternate')
      link.setAttribute('hreflang', lang)
      if (lang === 'en') {
        link.setAttribute('href', `${domain}${basePart}`)
      } else {
        link.setAttribute('href', `${domain}/${lang}${basePart}`)
      }

      this.dom.head.appendChild(link)
    })
  }

  private inverseKeys = object => Object.fromEntries(Object.entries(object).map(a => a.reverse()))
  private updateCurrentPath(lang: string) {
    let currentPath = this.location.path().replace(`/${this.inverseKeys(this.localizationCountry)[this.lang] || this.lang}`, `/${lang}`);
    if (!currentPath.includes(lang)) {
      currentPath = '/' + lang + currentPath;
    }

    this.router.navigate([currentPath]);
  }

  public localizationCountry = { 'ja': 'jp', 'zh-CN': 'zh', 'uk': 'ua' };
  public localizationAllowed = ['en', 'es', 'it', 'fr', 'de', 'pt', 'pl', 'tr', 'ru', 'ua', 'hi', 'ar', 'zh', 'jp', 'ko']
}
