import { Component, Output, EventEmitter, Input, OnInit, ChangeDetectionStrategy, OnChanges, OnDestroy, ElementRef, ViewChild, NgZone } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { AudioService } from 'src/app/services/audio.service'
import { IQuestion } from 'src/app/models/Question.model'
import { IAnswer } from 'src/app/models/Answer.model'
import { take, delay } from 'rxjs/operators'
import { of } from 'rxjs'
import { RandomizeService } from 'src/app/services/randomize.service'
import { ReportProblemPopupComponent } from '../../popups/Report-Dialogs/report-problem/report-problem.component'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'
import { AnimationItem } from 'lottie-web'
import * as Sentry from "@sentry/angular"

@Component({
  selector: 'audio-to-picture',
  templateUrl: './audio-to-picture.component.html',
  styleUrls: ['./audio-to-picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioToPictureGameComponent implements OnInit, OnChanges, OnDestroy {
  @Input() question: IQuestion
  @Input() test: boolean
  @Input() game: boolean
  @Input() comparison: boolean
  @Output() success = new EventEmitter()
  @Output() failed = new EventEmitter()

  @ViewChild('audiohelp') audiohelp: ElementRef

  public currentlang

  constructor(private ngZone: NgZone, private _dialog: MatDialog, private _RandomizeService: RandomizeService, private localizeHelperService: LocalizeHelperService, private audioService: AudioService) {}

  public questionId: number
  public selectedAnswer: IAnswer = null
  public checked = false
  public altAnswers = []
  public cardColors = []
  public timerId

  public animationItem: AnimationItem[] = []

  /* Clear selected answer and checked answer when inputs are changed */
  ngOnChanges() {
    this.selectedAnswer = null
    this.checked = false
    of(true)
      .pipe(delay(500), take(1))
      .subscribe(() => {
        this.audioService.playAudio(this.question.audioSrc, this.question.audioSrc)
      })

    if (this.altAnswers.length > 0 && this.question.id != this.questionId) {
      this.altAnswers = []
      this.createAltImagesArray(this.question)
    }
  }

  ngOnInit() {
    this.currentlang = this.localizeHelperService.getLangForced()

    this.questionId = this.question.id

    for (let i = 0; i < this.question.answers.length; i++) {
      const altImage = this._RandomizeService.getRandomAnswerImage(this.question.answers[i])
      const answer = {
        audioSrc: this.question.answers[i].audioSrc,
        correct: this.question.answers[i].correct,
        id: this.question.answers[i].id,
        name: this.question.answers[i].name,
        pictureSrc: altImage,
        imgType: altImage.includes('.json') ? 'json' : '',
        questionId: this.question.answers[i].questionId,
        text: this.question.answers[i].text,
      }
      this.altAnswers.push(answer)
    }
    this._RandomizeService.shuffle(this.altAnswers)

    this.audioService.preloadAudio(this.question.audioSrc, this.question.audioSrc)

    of(true)
      .pipe(delay(500), take(1))
      .subscribe(() => this.audioService.playAudio(this.question.audioSrc, this.question.audioSrc))

    this.cardColors = this._RandomizeService.getRandom12Colors()
  }

  ngOnDestroy() {
    clearTimeout(this.timerId)
    this.audioService.stopAllAudio()
  }

  public lottieFrameChanged(el) {
    if (el.detail.frame > 60) {
      el.target.pause()
    }
  }

  public audiohelpPlay() {
    this.audiohelp.nativeElement.play().catch((error) => {
      Sentry.captureException(error, {
        level: "error",
        extra: {
          location: 'audiohelpPlay AudioToPictureGameComponent',
          questionId: this.question.id,
          currentlang: this.currentlang,
        }
      })
    })
  }

  public createAltImagesArray(question) {
    for (let i = 0; i < question.answers.length; i++) {
      const altImage = this._RandomizeService.getRandomAnswerImage(question.answers[i])
      const answer = {
        audioSrc: question.answers[i].audioSrc,
        correct: question.answers[i].correct,
        id: question.answers[i].id,
        name: question.answers[i].name,
        pictureSrc: altImage,
        imgType: altImage.includes('.json') ? 'json' : '',
        questionId: question.answers[i].questionId,
        text: question.answers[i].text,
      }

      this.altAnswers.push(answer)
    }
    this._RandomizeService.shuffle(this.altAnswers)

    this.cardColors = this._RandomizeService.getRandom12Colors()
  }

  public selectAnswer(answer) {
    if (this.checked) return
    this.selectedAnswer = answer
  }

  public playAudio(src) {
    this.audioService.playAudio(src, src)
  }

  public setAnswer(answer) {
    this.audioService.stopAudio(this.question.audioSrc)
    if (answer.correct) this.success.emit({ question: this.question, answer })
    else this.failed.emit({ question: this.question, answer })
  }

  public checkAnswer() {
    this.checked = true
    if (this.selectedAnswer.correct) {
      this.audioService.playSoundCorrect()
    } else {
      this.audioService.playSoundIncorrect()
    }
  }

  public setAnswerTest(answer) {
    this.audioService.stopAudio(this.question.audioSrc)
    if (answer.correct) this.success.emit({ question: this.question, answer })
    else this.failed.emit({ question: this.question, answer })
  }

  public get checkRightAnswer() {
    return this.selectedAnswer.correct
  }

  public openReportPopup(question) {
    this._dialog.open(ReportProblemPopupComponent, {
      closeOnNavigation: true,
      hasBackdrop: true,
      data: question,
      panelClass: 'medium-adaptive-popup',
    })
  }

  public playAnim(index) {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem[index].play()
    })
  }

  public stopAnim(index) {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem[index].pause()
    })
  }

  public animationCreated(animationItem: AnimationItem, index): void {
    this.animationItem[index] = animationItem
  }
}
